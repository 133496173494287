import React from 'react';

function Footer() {
  return (
    <footer className="bg-blue-900 text-white py-8">
      <div className="container mx-auto px-4 text-center">
        <h4 className="text-lg font-bold">© 2024 Selcom</h4>
        <p className="text-gray-300">Todos los derechos reservados.</p>      
      </div>
    </footer>
  );
}

export default Footer;
