import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import 'animate.css';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Grid,
  Text,
  Icon,
  Heading,
  Button,
  Container
} from '@chakra-ui/react';
import { FaLock, FaShieldAlt, FaPhone, FaTools, FaDraftingCompass, FaHammer, FaTrophy, FaUsers, FaBusinessTime } from 'react-icons/fa'; 
import { MdSecurity } from 'react-icons/md';

function Home() {
  const { t } = useTranslation();

  useEffect(() => {
    const statsItems = document.querySelectorAll('.stat-item');
    const handleScroll = () => {
      statsItems.forEach(item => {
        const rect = item.getBoundingClientRect();
        if (rect.top < window.innerHeight - 50) {
          item.classList.add('in-view');
        }
      });
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="home">
      {/* Hero Section */}
      <section id="home" className="relative bg-cover bg-center h-screen text-white" style={{ backgroundImage: "url('images/tim-kabel-FCrYdP8gohg-unsplash.jpg')" }}>
        <div className="absolute inset-0 bg-gradient-to-r from-gray-900 to-gray-700 opacity-70"></div>
        <div className="relative z-10 flex flex-col items-center justify-center h-full text-center">
          <h1 className="text-4xl md:text-6xl font-bold mb-6 drop-shadow-lg animate__animated animate__fadeIn">
            {t('home.heroTitle')}
          </h1>
          <p className="text-lg md:text-2xl mb-8 max-w-2xl drop-shadow-md animate__animated animate__fadeIn animate__delay-1s">
            {t('home.heroSubtitle')}
          </p>

          <Button
            as={Link}
            to="/about"
            colorScheme="teal"
            size="lg"
            rounded="full"
            _hover={{ bg: 'teal.600', transform: 'scale(1.05)' }}
            className="animate__animated animate__fadeIn animate__delay-2s"
          >
            {t('home.aboutUsButton')}
          </Button>
        </div>
      </section>

      {/* About Section */}
      <Box
        bgGradient="linear(to-r, blue.50, gray.100)"
        borderRadius="lg"
        boxShadow="md"
        p={10}
        border="1px solid"
        borderColor="blue.200"
        textAlign="center"
        maxW="800px"
        mx="auto"
        my={12}
      >
        <Icon as={MdSecurity} boxSize={8} color="blue.600" mb={4} />
        <h2 className="text-3xl md:text-5xl font-bold text-blue-900 animate__animated animate__fadeInUp">
          {t('home.aboutTitle')}
        </h2>
        <Text fontSize={{ base: "md", md: "lg" }} mt={6} color="gray.700" textAlign="center" letterSpacing="wider" lineHeight="1.5">
          {t('home.aboutDescription')}
        </Text>
      </Box>

      {/* Three Questions Section */}
      <div className="bg-white py-20">
        <Container maxW="container.xl" textAlign="center">
          <h2 className="text-3xl md:text-5xl font-bold text-blue-900 mb-12">
            {t('home.whatWeOffer')}
          </h2>
          <Grid templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }} gap={10}>
            {[
              { icon: FaLock, title: t('home.offerTitle1'), description: t('home.offerDescription1') },
              { icon: FaShieldAlt, title: t('home.offerTitle2'), description: t('home.offerDescription2') },
              { icon: FaPhone, title: t('home.offerTitle3'), description: t('home.offerDescription3') }
            ].map((item, index) => (
              <Box
                key={index}
                bg={index === 1 ? "blue.400" : "green.400"}
                rounded="lg"
                p={8}
                shadow="lg"
                _hover={{ shadow: "xl", transform: "scale(1.05)" }}
                transition="transform 0.3s"
              >
                <Icon as={item.icon} w={10} h={10} color="white" />
                <Heading as="h3" size="lg" color="white" mt={4}>{item.title}</Heading>
                <Text mt={2} color="white">{item.description}</Text>
              </Box>
            ))}
          </Grid>
        </Container>
      </div>

      {/* Services Section */}
      <section id="services" className="py-20 bg-gray-50 text-center">
        <Container maxW="container.xl">
          <h2 className="text-3xl md:text-5xl font-bold text-blue-900 mb-12">
            {t('home.servicesTitle')}
          </h2>
          <Text fontSize={{ base: "lg", md: "xl" }} color="gray.600" mb={8}>
            {t('home.servicesDescription')}
          </Text>
          <Button
            as={Link}
            to="/services"
            colorScheme="teal"
            size="lg"
            rounded="full"
            _hover={{ bg: 'teal.600', transform: 'scale(1.05)' }}
            className="animate__animated animate__fadeIn animate__delay-1s"
          >
            {t('home.exploreServicesButton')}
          </Button>
          <Grid templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }} gap={8} px={6} mt={10}>
            {[{ title: t('home.service1Title'), description: t('home.service1Description'), icon: FaTools },
              { title: t('home.service2Title'), description: t('home.service2Description'), icon: FaDraftingCompass },
              { title: t('home.service3Title'), description: t('home.service3Description'), icon: FaHammer }
            ].map((service, index) => (
              <Box
                key={index}
                bg="white"
                p={8}
                rounded="lg"
                shadow="lg"
                _hover={{ shadow: "xl", transform: "scale(1.05)", transition: "0.3s" }}
                transition="transform 0.3s, box-shadow 0.3s"
                position="relative"
                overflow="hidden"
              >
                <Icon as={service.icon} w={8} h={8} color="blue.500" />
                <Heading as="h3" size="lg" mt={4}>{service.title}</Heading>
                <Text mt={2} color="gray.600">{service.description}</Text>
              </Box>
            ))}
          </Grid>
        </Container>
      </section>


{/* Empresas e Instituciones que Confían en Nosotros */}
<Box py={20} bg="gray.100" textAlign="center">
  <Heading 
    as="h2" 
    size="2xl" 
    color="blue.900" 
    mb={12} 
    className="animate__animated animate__fadeInDown"
    style={{ fontFamily: 'Magistral Medium Italic' }}
  >
    {t('home.empresasConfian')}
  </Heading>
  <Carousel 
    autoPlay 
    interval={3000} 
    infiniteLoop 
    showThumbs={false} 
    showStatus={false} 
    transitionTime={500}
  >
    {Array.from({ length: Math.ceil(18 / 4) }, (_, i) => (
      <Box className="flex justify-around animate__animated animate__slideInUp" key={i} bg="gray.100">
        {[
          "images/Ministerio de Obras Publicas y Comunicaciones PY.jpg",
          "images/Corte Suprema PY.png",
          "images/Camara de Diputados.png",
          "images/Aduana.jpeg",
          "images/SNPP.png",
          "images/IPSlogo.png",
          "images/UNA.jpeg",
          "images/WTC Asuncion.jpeg",
          "images/Banco do Brasil.png",
          "images/Yacyreta.webp",
          "images/CIT-LOGO-.png",
          "images/LOGO-TEATRO-MUNICIPAL-01.webp",
          "images/Axion.jpg",
          "images/Cooperativa Lambare.gif",
          "images/petropar.webp",
          "images/Hierropar.jpeg",
          "images/chacomer_logo.png",
          "images/OIP.jpeg"
        ].slice(i * 4, (i + 1) * 4).map((brand, index) => (
          <Box key={index} p={2}>
            <img 
              src={brand} 
              alt={`Marca ${index + 1}`} 
              className="h-20 w-20 object-contain transition-transform duration-300 hover:scale-105"
              style={{
                width: '120px', // Ajusta el tamaño que prefieras
                height: '120px', // Ajusta el tamaño que prefieras
                objectFit: 'contain', // No distorsionar las imágenes
                transition: 'transform 0.3s ease', 
                backgroundColor: 'gray.100'
              }}
            />
          </Box>
        ))}
      </Box>
    ))}
  </Carousel>
</Box>


      {/* Horizontal Line Separator */}
      <hr style={{ border: 'none', borderTop: '2px solid #E2E8F0', margin: '20px 0' }} />

      {/* Constructoras Aliadas */}
<Box py={20} bg="gray.100" textAlign="center">
  <Heading 
    as="h2" 
    size="2xl" 
    color="blue.900" 
    mb={12} 
    className="animate__animated animate__fadeInDown"
    style={{ fontFamily: 'Magistral Medium Italic' }}
  >
    {t('home.constructorasAliadas')}
  </Heading>
  <Carousel 
    autoPlay 
    interval={3000} 
    infiniteLoop 
    showThumbs={false} 
    showStatus={false} 
    transitionTime={500}
  >
    {Array.from({ length: Math.ceil(12 / 4) }, (_, i) => (
      <Box className="flex justify-around animate__animated animate__slideInUp" key={i} bg="gray.100">
        {[
          "images/CIVSA.png", 
          "images/SCC.jpg",
          "images/Tedec.jpg", 
          "images/Itasa.png", 
          "images/Implenia.jpeg", 
          "images/Jimenez.jpeg", 
          "images/MM SA.jpg", 
          "images/AGB.jpeg", 
          "images/Diaz_M.jpg",
          "images/CIVSA.png", 
          "images/SCC.jpg",
          "images/Tedec.jpg",
        ].slice(i * 4, (i + 1) * 4).map((brand, index) => (
          <Box key={index} p={2}>
            <img 
              src={brand} 
              alt={`Constructoras Aliadas ${index + 1}`} 
              className="h-24 w-24 object-contain transition-transform duration-300 hover:scale-105"
              style={{
                width: '120px', // Ajuste para un tamaño mayor
                height: '120px', // Ajuste para un tamaño mayor
                objectFit: 'contain', // Mantiene la proporción
                transition: 'transform 0.3s ease', 
                backgroundColor: 'gray.100' // Corrige el color de fondo
              }}
            />
          </Box>
        ))}
      </Box>
    ))}
  </Carousel>
</Box>


      {/* Horizontal Line Separator */}
      <hr style={{ border: 'none', borderTop: '2px solid #E2E8F0', margin: '20px 0' }} />

      {/* Nuestras Marcas */}
<Box py={20} bg="gray.100" textAlign="center">
  <Heading 
    as="h2" 
    size="2xl" 
    color="blue.900" 
    mb={12} 
    className="animate__animated animate__fadeInDown"
    style={{ fontFamily: 'Magistral Medium Italic' }}
  >
    {t('home.nuestrasMarcas')}
  </Heading>
  <Carousel 
    autoPlay 
    interval={3000} 
    infiniteLoop 
    showThumbs={false} 
    showStatus={false} 
    transitionTime={500}
  >
    {Array.from({ length: Math.ceil(16 / 4) }, (_, i) => (
      <Box className="flex justify-around animate__animated animate__slideInUp" key={i} bg="gray.100">
        {[
          "images/Furukawa.png", 
          "images/Ubiquiti.png", 
          "images/zeta.jpg", 
          "images/teledata.png", 
          "images/Asenware.png", 
          "images/CDVI.jpg", 
          "images/alhua.jpeg", 
          "images/GONSIN.jpeg", 
          "images/tp link.png",
          "images/HIK VISION.png", 
          "images/HDL.png", 
          "images/NAPCO.png", 
          "images/FIRECLASS.webp", 
          "images/Siera.png", 
          "images/exacq.png", 
          "images/speco.jpg"
        ].slice(i * 4, (i + 1) * 4).map((brand, index) => (
          <Box key={index} p={2}>
            <img 
              src={brand} 
              alt={`Marca ${index + 1}`} 
              className="h-32 w-auto max-w-full object-contain transition-transform duration-300 hover:scale-105" 
              style={{ 
                transition: 'transform 0.3s ease', 
                backgroundColor: 'gray.100', // Corregido el color de fondo
                width: '150px', // Ajustado para tamaño mayor
                height: '150px' // Ajustado para tamaño mayor
              }}
            />
          </Box>
        ))}
      </Box>
    ))}
  </Carousel>
</Box>


{/* Stats Section */}
<section className="py-20 bg-gray-200 text-center">
  <h2 className="text-4xl font-bold text-blue-900 mb-8 animate__animated animate__fadeIn">
    {t('home.nuestrasEstadisticas')}
  </h2>
  <div className="grid grid-cols-1 md:grid-cols-3 gap-6 px-4">
    {[{ number: "+100", label: t('home.proyectosCompletados'), icon: <FaTrophy className="text-4xl" /> },
      { number: "+50", label: t('home.clientesSatisfechos'), icon: <FaUsers className="text-4xl" /> },
      { number: "+10", label: t('home.anosExperiencia'), icon: <FaBusinessTime className="text-4xl" /> }
    ].map((stat, index) => (
      <div 
        key={index} 
        className={`stat-item bg-white p-4 rounded-lg shadow-lg hover:shadow-xl transition-transform duration-300 hover:scale-105 border-b-4 border-transparent hover:border-green-500 animate__animated animate__fadeInUp animate__delay-${index + 1}s`}
      >
        <div className="flex items-center justify-center mb-3">
          <div className="bg-green-500 text-white p-3 rounded-full shadow-md">
            {stat.icon}
          </div>
        </div>
        <h3 className="text-3xl font-bold text-green-700">{stat.number}</h3>
        <p className="text-gray-700 mt-1 text-lg font-semibold">{stat.label}</p>
      </div>
    ))}
  </div>
</section>

{/* Contact Section */}
<section className="py-20 bg-white text-center relative overflow-hidden mt-12">
  <h2 className="text-5xl font-bold text-blue-900 mb-6 animate__animated animate__fadeIn">
    {t('home.contactanos')}
  </h2>
  <p className="text-lg text-gray-700 mb-8 animate__animated animate__fadeIn animate__delay-1s">
    {t('home.estamosAquiParaAyudarte')}
  </p>

  {/* Contact Button */}
  <Link 
    to="/contact" 
    className="bg-green-500 text-white py-4 px-10 rounded-full shadow-lg hover:bg-green-600 transition-all duration-300 animate__animated animate__bounce animate__delay-3s transform hover:scale-105"
  >
    {t('home.irAContacto')}
  </Link>
</section>

    </div>
  );
}

export default Home;
