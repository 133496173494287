import React, { useState, useRef } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Box, Heading, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

function Projects() {
  const { t } = useTranslation(); // Hook para acceder a las traducciones
  const [visibleProjects, setVisibleProjects] = useState(3); // Número inicial de proyectos visibles
  const projectsRef = useRef(null); // Ref para la sección de proyectos

  const projects = [
    {
      image: '/images/EBAS.jpg',
      title: t('projects.list.0.title'), // Usar traducción para el título
      description: t('projects.list.0.description'), // Usar traducción para la descripción
    },
    {
      image: '/images/hospital_acosta_nu.png',
      title: t('projects.list.1.title'),
      description: t('projects.list.1.description'),
    },
    {
      image: '/images/complexo_santo_domingo.jpg',
      title: t('projects.list.2.title'),
      description: t('projects.list.2.description'),
    },
    {
      image: '/images/Boulevard.jpg',
      title: t('projects.list.12.title'),
      description: t('projects.list.12.description'),
    },
    {
      image: '/images/junta-municipal-.jpg',
      title: t('projects.list.3.title'),
      description: t('projects.list.3.description'),
    },
    {
      image: '/images/cooperativa_lambare.jpg',
      title: t('projects.list.4.title'),
      description: t('projects.list.4.description'),
    },
    {
      image: '/images/hospital_ingavi.jpg',
      title: t('projects.list.5.title'),
      description: t('projects.list.5.description'),
    },
    {
      image: '/images/registro_automotores.jpeg',
      title: t('projects.list.6.title'),
      description: t('projects.list.6.description'),
    },
    {
      image: '/images/trinity_towers.jpg',
      title: t('projects.list.7.title'),
      description: t('projects.list.7.description'),
    },
    {
      image: '/images/banco_do_brasil.jpg',
      title: t('projects.list.8.title'),
      description: t('projects.list.8.description'),
    },
    {
      image: '/images/camara_de_diputados.jpg',
      title: t('projects.list.9.title'),
      description: t('projects.list.9.description'),
    },
    {
      image: '/images/palacio_de_justicia.jpg',
      title: t('projects.list.10.title'),
      description: t('projects.list.10.description'),
    },
    {
      image: '/images/Itaipu.jpg',
      title: t('projects.list.11.title'),
      description: t('projects.list.11.description'),
    },
    
  ];

  const showMoreProjects = () => {
    setVisibleProjects((prev) => prev + 3); // Incrementa el número de proyectos visibles en 3
  };

  const showLessProjects = () => {
    setVisibleProjects(3); // Resetea a la cantidad inicial
    if (projectsRef.current) {
      projectsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="projects">
      <section id="projects" className="bg-white text-center py-20" ref={projectsRef}>
        <h2 className="text-5xl font-bold text-blue-900 mb-10">{t('projects.title')}</h2>
        <p className="text-lg text-gray-700 mb-16">
          {t('projects.description')}
        </p>
      </section>

      {/* Projects Grid */}
      <section className="py-16 bg-gray-100 text-center">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 px-6">
          {projects.slice(0, visibleProjects).map((project, index) => (
            <div
              key={index}
              className="project-card group relative overflow-hidden rounded-lg shadow-lg transform transition duration-500 hover:scale-105"
              data-aos="fade-up"
            >
              {/* Image with Overlay */}
              <div className="project-image relative w-full h-72 overflow-hidden">
                <img
                  src={project.image}
                  alt={project.title}
                  className="w-full h-full object-cover transition-transform duration-700 group-hover:scale-110"
                />
                <div className="absolute inset-0 bg-blue-900 bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
              </div>

              {/* Hover Description */}
              <div className="absolute inset-0 flex items-center justify-center text-center p-6 opacity-0 group-hover:opacity-100 transition-opacity duration-700 text-white bg-black bg-opacity-60">
                <p className="text-lg font-medium">{project.description}</p>
              </div>

              {/* Title */}
              <div className="absolute bottom-4 left-4 text-white z-10 group-hover:translate-y-3 transition-transform duration-500">
                <h3 className="text-2xl font-bold">{project.title}</h3>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Ver más / Ver menos */}
      <Box textAlign="center" py={4}>
        <Button 
          onClick={visibleProjects < projects.length ? showMoreProjects : showLessProjects} 
          colorScheme="teal"
          variant="link"
          fontSize="lg"
          _hover={{ 
            textDecoration: "underline",
            color: "teal.500",
          }}
          transition="color 0.3s, text-decoration 0.3s"
        >
          {visibleProjects < projects.length ? t('projects.showMore') : t('projects.showLess')}
        </Button>
      </Box>

      {/* Nuestras Marcas */}
      <Box py={20} bg="gray.100" textAlign="center">
        <Heading 
          as="h2" 
          size="2xl" 
          color="blue.900" 
          mb={12} 
          className="animate__animated animate__fadeInDown"
          style={{ fontFamily: 'Magistral Medium Italic' }}
        >
          {t('projects.technologies')}
        </Heading>
        <Carousel 
          autoPlay 
          interval={3000} 
          infiniteLoop 
          showThumbs={false} 
          showStatus={false} 
          transitionTime={500}
        >
          {Array.from({ length: Math.ceil(16 / 4) }, (_, i) => (
            <Box className="flex justify-around" key={i} bg="gray.100">
              {[
                "images/Furukawa.png", 
                "images/Ubiquiti.png", 
                "images/zeta.jpg", 
                "images/teledata.png", 
                "images/Asenware.png", 
                "images/CDVI.jpg", 
                "images/alhua.jpeg", 
                "images/GONSIN.jpeg", 
                "images/tp link.png",
                "images/HIK VISION.png", 
                "images/HDL.png", 
                "images/NAPCO.png", 
                "images/FIRECLASS.webp", 
                "images/Siera.png", 
                "images/exacq.png", 
                "images/speco.jpg"
              ].slice(i * 4, (i + 1) * 4).map((brand, index) => (
                <Box key={index} p={2}>
            <img 
              src={brand} 
              alt={`Marca ${index + 1}`} 
              className="h-32 w-auto max-w-full object-contain transition-transform duration-300 hover:scale-105" 
              style={{ 
                transition: 'transform 0.3s ease', 
                backgroundColor: 'gray.100', // Corregido el color de fondo
                width: '150px', // Ajustado para tamaño mayor
                height: '150px' // Ajustado para tamaño mayor
              }}
            />
          </Box>
              ))} 
            </Box>
          ))}
        </Carousel>
      </Box>
    </div>
  );
}

export default Projects;
