import React from 'react';
import { Link } from 'react-router-dom';
import { FaRegClock, FaUsers, FaShieldAlt, FaBullseye, FaEye } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

function About() {
  const { t } = useTranslation();

  return (
    <section id="about" className="bg-gray-100 py-16">
      <div className="container mx-auto px-4">
        {/* Título y descripción */}
        <div className="text-center mb-12">
          <h2 className="text-5xl font-semibold text-blue-800 mt-8">{t('about_title')}</h2>
          <p className="text-lg text-gray-600 mt-4 max-w-2xl mx-auto">
            {t('about_description')}
          </p>
        </div>

 {/* Información y equipo */}
<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
  <div className="flex justify-center items-center">
    <img
      src="images/prueba_4.jpg"
      alt={t('about_team_image_alt')}
      className="rounded-lg shadow-lg object-contain transition-transform duration-300 hover:scale-105"
      style={{
        maxWidth: '80%', // Ajusta el tamaño al 80% del contenedor
        height: 'auto', // Mantiene la proporción de la imagen
        backgroundColor: '#f0f0f0', // Fondo gris claro si se necesita
      }}
    />
  </div>
          <div className="flex flex-col justify-center">
            <h3 className="text-2xl font-bold text-blue-800">{t('about_history_title')}</h3>
            <p className="text-gray-700 mt-4">{t('about_history_first_paragraph')}</p>
            <p className="text-gray-700 mt-4">{t('about_history_second_paragraph')}</p>
            <Link to="/contact" className="mt-6 inline-block bg-green-500 text-white py-3 px-8 rounded-full hover:bg-green-600 transition-all">
              {t('contact_us')}
            </Link>
          </div>
        </div>

        {/* Nuestros Valores */}
        <div className="mt-16 bg-gray-100 py-10">
          <h3 className="text-4xl font-bold text-blue-800 text-center mb-8">{t('about_values_title')}</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 px-4">
            <div className="bg-white p-8 rounded-lg shadow-lg text-center transform transition-transform hover:scale-105 hover:shadow-xl border border-gray-200">
              <div className="flex items-center justify-center mb-4">
                <div className="bg-green-100 p-3 rounded-full">
                  <FaRegClock className="text-green-500 text-4xl" />
                </div>
              </div>
              <h4 className="text-2xl font-bold text-green-600">{t('value_innovation')}</h4>
              <p className="text-gray-700 mt-4">
                {t('value_innovation_description')}
              </p>
            </div>
            <div className="bg-white p-8 rounded-lg shadow-lg text-center transform transition-transform hover:scale-105 hover:shadow-xl border border-gray-200">
              <div className="flex items-center justify-center mb-4">
                <div className="bg-green-100 p-3 rounded-full">
                  <FaUsers className="text-green-500 text-4xl" />
                </div>
              </div>
              <h4 className="text-2xl font-bold text-green-600">{t('value_quality')}</h4>
              <p className="text-gray-700 mt-4">
                {t('value_quality_description')}
              </p>
            </div>
            <div className="bg-white p-8 rounded-lg shadow-lg text-center transform transition-transform hover:scale-105 hover:shadow-xl border border-gray-200">
              <div className="flex items-center justify-center mb-4">
                <div className="bg-green-100 p-3 rounded-full">
                  <FaShieldAlt className="text-green-500 text-4xl" />
                </div>
              </div>
              <h4 className="text-2xl font-bold text-green-600">{t('value_commitment')}</h4>
              <p className="text-gray-700 mt-4">
                {t('value_commitment_description')}
              </p>
            </div>
          </div>
        </div>

        {/* Misión y Visión */}
        <div className="mt-16 bg-gray-100 py-10">
          <h3 className="text-5xl font-extrabold text-blue-800 text-center mb-10">{t('mission_vision_title')}</h3>
          <div className="max-w-6xl mx-auto text-center px-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="bg-white p-4 rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105 hover:shadow-2xl">
                <div className="flex items-center justify-center mb-3">
                  <div className="bg-green-100 p-3 rounded-full shadow-md">
                    <FaBullseye className="text-green-500 text-4xl" />
                  </div>
                </div>
                <h4 className="text-2xl font-semibold text-green-600">{t('mission_title')}</h4>
                <p className="text-base text-gray-700 mt-2 leading-relaxed">
                  {t('mission_description')}
                </p>
              </div>
              <div className="bg-white p-4 rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105 hover:shadow-2xl">
                <div className="flex items-center justify-center mb-3">
                  <div className="bg-green-100 p-3 rounded-full shadow-md">
                    <FaEye className="text-green-500 text-4xl" />
                  </div>
                </div>
                <h4 className="text-2xl font-semibold text-green-600">{t('vision_title')}</h4>
                <p className="text-base text-gray-700 mt-2 leading-relaxed">
                  {t('vision_description')}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Nuestro Equipo */}
        <div className="mt-16">
          <h3 className="text-3xl font-bold text-blue-800 text-center mb-6">{t('team_title')}</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-white p-6 rounded-lg shadow-lg text-center">
              <h4 className="text-xl font-bold text-blue-800">{t('team_member_1_name')}</h4>
              <p className="text-gray-700 mt-2">{t('team_member_1_role')}</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg text-center">
              <h4 className="text-xl font-bold text-blue-800">{t('team_member_2_name')}</h4>
              <p className="text-gray-700 mt-2">{t('team_member_2_role')}</p>
            </div>
            <div className="bg-white p-6 rounded-lg shadow-lg text-center">
              <h4 className="text-xl font-bold text-blue-800">{t('team_member_3_name')}</h4>
              <p className="text-gray-700 mt-2">{t('team_member_3_role')}</p>
            </div>
          </div>
        </div>

        {/* Why Choose Us Section */}
        <div className="mt-16">
          <h3 className="text-3xl font-bold text-blue-800 text-center mb-6">{t('why_choose_us_title')}</h3>
          <p className="text-lg text-gray-600 text-center max-w-3xl mx-auto">
            {t('why_choose_us_description')}
          </p>
          <div className="flex justify-center mt-8">
            <Link to="/portfolio" className="bg-blue-800 text-white py-3 px-8 rounded-full hover:bg-green-500 transition-all">
              {t('view_projects')}
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
