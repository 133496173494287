import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import Home from './components/Home';
import About from './components/About';
import Services from './components/Services';
import Contact from './components/Contact';
import Portfolio from './components/Portfolio';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import './assets/fonts/fonts.css';
import './i18n'; // Importa la configuración de i18next
import { useTranslation } from 'react-i18next';

function App() {
  // Configuración de traducción
  const { t } = useTranslation();

  return (
    <Router>
      <div className="App">
        <ScrollToTop />
        {/* Barra de navegación */}
        <Navbar />
        <div className="h-16"></div> {/* Espaciado para la navbar */}

        {/* Rutas principales */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>

        {/* Pie de página */}
        <Footer />
      </div>
    </Router>
  );
}

export default App;
