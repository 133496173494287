import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { useTranslation } from 'react-i18next';

function Contact() {
  const { t } = useTranslation();
  // Estado para los datos del formulario y errores
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState({});

  // Manejo de cambios en el formulario
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Validación de datos del formulario
  const validate = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = t('contact.nameRequired');
    if (!formData.email) {
      newErrors.email = t('contact.emailRequired');
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = t('contact.invalidEmail');
    }
    if (!formData.message) newErrors.message = t('contact.messageRequired');
    return newErrors;
  };

  // Manejo de envío del formulario
  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    // Envío de correo usando EmailJS
    emailjs.send('service_mscsasj', 'template_8us6cbc', formData, 'MgwfKdPP6r-JES86N')
      .then((response) => {
        console.log('Email enviado:', response.status, response.text);
        setSubmitted(true);
        setFormData({ name: '', email: '', message: '' }); // Reiniciar el formulario
        setErrors({}); // Limpiar errores
      })
      .catch((error) => {
        console.error('Error al enviar el correo:', error);
      });
  };

  // Manejo de redirección móvil a Waze o Google Maps
  const handleMobileRedirect = () => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    const latitude = -25.3042304;  // Latitud de Selcom
    const longitude = -57.6148147; // Longitud de Selcom
    const wazeUrl = `https://waze.com/ul?ll=${latitude},${longitude}&navigate=yes`;
    const googleMapsUrl = `https://maps.google.com/?q=${latitude},${longitude}`;

    if (isMobile) {
      // Si el usuario está en un móvil, mostrar un prompt para elegir Waze o Google Maps
      const useWaze = window.confirm(t('contact.openInWaze'));
      if (useWaze) {
        window.location.href = wazeUrl;
      } else {
        window.location.href = googleMapsUrl;
      }
    } else {
      // En caso de que no sea un móvil, mostrar Google Maps en el navegador
      window.location.href = googleMapsUrl;
    }
  };

  return (
    <section id="contact" className="bg-gradient-to-br from-white to-gray-50 py-24">
      <div className="container mx-auto px-6">
        {/* Título y descripción */}
        <div className="text-center mb-16">
          <h2 className="text-5xl font-extrabold text-blue-900 mb-4">{t('contact.title')}</h2>
          <p className="text-lg text-gray-500 mt-2">{t('contact.description')}</p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-16">
          {/* Formulario de Contacto */}
          <div className="bg-white shadow-lg rounded-lg p-8 transition-transform hover:scale-105">
            <h3 className="text-4xl font-bold text-blue-900 mb-6">{t('contact.formTitle')}</h3>
            {submitted && <p className="text-green-500 mb-4">{t('contact.successMessage')}</p>}
            <form onSubmit={handleSubmit} className="space-y-4">
              <input 
                type="text" 
                name="name" 
                placeholder={t('contact.form.placeholders.name')} 
                value={formData.name} 
                onChange={handleChange} 
                className={`w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-300 ease-in-out ${errors.name ? 'border-red-500' : 'focus:border-blue-500'}`} 
                required 
              />
              {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
              
              <input 
                type="email" 
                name="email" 
                placeholder={t('contact.form.placeholders.email')} 
                value={formData.email} 
                onChange={handleChange} 
                className={`w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-300 ease-in-out ${errors.email ? 'border-red-500' : 'focus:border-blue-500'}`} 
                required 
              />
              {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
              
              <textarea 
                name="message" 
                placeholder={t('contact.form.placeholders.message')} 
                value={formData.message} 
                onChange={handleChange} 
                className={`w-full p-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all duration-300 ease-in-out ${errors.message ? 'border-red-500' : 'focus:border-blue-500'}`} 
                required 
              />
              {errors.message && <p className="text-red-500 text-sm">{errors.message}</p>}
              
              <button 
                type="submit" 
                className="bg-blue-900 text-white py-3 px-6 rounded-full transition-all duration-300 ease-in-out hover:bg-green-500 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-green-500"
              >
                {t('contact.form.submitButton')}
              </button>
            </form>
          </div>

          {/* Información de Contacto */}
          <div className="space-y-8">
            <div className="bg-white shadow-2xl rounded-lg p-10 hover:scale-105 transition-transform">
              <h3 className="text-3xl font-bold text-blue-900 mb-6">{t('contact.detailsTitle')}</h3>
              <ul className="text-gray-600 space-y-4">
                <li><strong>{t('contact.address')}</strong> Calle 12 de Octubre, Asunción, Paraguay</li>
                <li>
                  <strong>{t('contact.phonesTitle')}</strong>
                  <ul className="list-disc list-inside space-y-2 mt-1">
                    <li>Ing. Rodney Ahrens: <a href="tel:+595991559922" className="text-blue-600 underline">+595 991 559 922</a></li>
                    <li>Ing. Anibal Ahrens: <a href="tel:+595981848394" className="text-blue-600 underline">+595 981 848 394</a></li>
                    <li>Lic. Andy Ahrens: <a href="tel:+595981232013" className="text-blue-600 underline">+595 981 232 013</a></li>
                  </ul>
                </li>
                <li>
                  <strong>{t('contact.emailTitle')}</strong>
                  <ul className="list-disc list-inside space-y-2 mt-1">
                    <li><a href="mailto:anibal@selcom.com.py" className="text-blue-600 underline">anibal@selcom.com.py</a></li>
                    <li><a href="mailto:rodney@selcom.com.py" className="text-blue-600 underline">rodney@selcom.com.py</a></li>
                  </ul>
                </li>
                <li><strong>{t('contact.hours')}</strong></li>
              </ul>
            </div>

  {/* Redes Sociales */}
<div className="bg-white shadow-2xl rounded-lg p-6 hover:scale-105 transition-transform">
  <h3 className="text-3xl font-bold text-blue-900 mb-4 text-center">{t('contact.socialsTitle')}</h3>
  <div className="flex justify-center space-x-8 my-4">
    <div className="flex flex-col items-center">
      <a
        href="https://www.facebook.com/selcomsrl.com.py"
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-900 hover:text-blue-700"
      >
        <FontAwesomeIcon icon={faFacebookF} size="2x" />
      </a>
      <span className="text-sm text-gray-600 mt-2">{t('contact.facebook')}</span>
    </div>
    <div className="flex flex-col items-center">
      <a
        href="https://www.instagram.com/selcom_py"
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-900 hover:text-blue-700"
      >
        <FontAwesomeIcon icon={faInstagram} size="2x" />
      </a>
      <span className="text-sm text-gray-600 mt-2">{t('contact.instagram')}</span>
    </div>
  </div>
</div>

            {/* Mapa */}
<div className="bg-white shadow-2xl rounded-lg p-6 hover:scale-105 transition-transform">
  <h3 className="text-3xl font-bold text-blue-900 mb-4 text-center">{t('contact.mapTitle')}</h3>
  
  {/* Mapa de Google */}
        <div className="relative overflow-hidden" style={{ paddingTop: '56.25%' }}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7213.998158804683!2d-57.61224000000001!3d-25.304235!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x945da87029d6f5ad%3A0xd30e739d8d25d55!2sSELCOM%20SRL!5e0!3m2!1sen!2spy!4v1730900177054!5m2!1sen!2spy"
            width="100%" height="450" style={{ border: '0', position: 'absolute', top: '0', left: '0' }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>

  <button
    onClick={handleMobileRedirect}
    className="bg-blue-900 text-white py-3 px-8 rounded-full hover:bg-green-500 transition-all shadow-lg mx-auto block mt-6"
  >
    {t('contact.openMapButton')}
  </button>
</div>

          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
