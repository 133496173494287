import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes, FaEnvelope } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const [showFlags, setShowFlags] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = () => {
    setIsOpen(false); // Cierra el menú al seleccionar una opción
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setShowFlags(false); // Cierra las banderas tras seleccionar un idioma
  };

  return (
    <nav className="fixed w-full z-50 bg-white shadow-lg backdrop-blur-lg border-b border-gray-300">
      <div className="container mx-auto px-2 py-2 flex justify-between items-center">
        {/* Logo */}
        <Link to="/" className="flex items-center">
          <img
            src="/images/Logo_Selcom-removebg_2.png"
            alt="Logo de Selcom"
            className="h-16 lg:h-24 w-auto mr-4"
          />
        </Link>

        {/* Botón de menú en móvil */}
        <div className="md:hidden">
          <button onClick={toggleMenu} className="focus:outline-none">
            {isOpen ? <FaTimes size={24} className="text-gray-800" /> : <FaBars size={24} className="text-gray-800" />}
          </button>
        </div>

        {/* Enlaces y cambio de idioma para escritorio */}
        <div className="hidden md:flex justify-center items-center space-x-4">
          <ul className="flex space-x-8">
            <li>
              <Link
                to="/about"
                className="hover:bg-green-600 text-gray-800 font-semibold hover:text-white transition-colors duration-300 px-4 py-2 rounded-full"
              >
                {t('about')}
              </Link>
            </li>
            <li>
              <Link
                to="/services"
                className="hover:bg-green-600 text-gray-800 font-semibold hover:text-white transition-colors duration-300 px-4 py-2 rounded-full"
              >
                {t('services')}
              </Link>
            </li>
            <li>
              <Link
                to="/portfolio"
                className="hover:bg-green-600 text-gray-800 font-semibold hover:text-white transition-colors duration-300 px-4 py-2 rounded-full"
              >
                {t('portfolio')}
              </Link>
            </li>
          </ul>

          {/* Botón de Contacto */}
          <Link
            to="/contact"
            className="flex items-center bg-green-600 text-white font-semibold transition-colors duration-300 py-3 px-6 rounded-full shadow-lg hover:bg-green-700 transform hover:scale-105"
          >
            <FaEnvelope className="mr-2" />
            {t('contact.title')}
          </Link>

          {/* Botones para cambiar el idioma con banderas */}
          <div className="flex items-center space-x-4">
            <div className="relative">
              <button
                onClick={() => changeLanguage('es')}
                className="p-2 rounded-full transition-all duration-300 hover:scale-110 hover:shadow-lg"
              >
                <img src="/images/spain-flag.png" alt="Español" className="w-8 h-8 rounded-full border-2 border-gray-300" />
              </button>
            </div>

            <div className="relative">
              <button
                onClick={() => changeLanguage('en')}
                className="p-2 rounded-full transition-all duration-300 hover:scale-110 hover:shadow-lg"
              >
                <img src="/images/uk-flag.jpeg" alt="English" className="w-8 h-8 rounded-full border-2 border-gray-300" />
              </button>
            </div>
          </div>
        </div>

        {/* Menú móvil */}
        <div
          className={`absolute top-16 left-0 w-full bg-white backdrop-blur-lg text-center transition-transform duration-300 ease-in-out shadow-lg ${isOpen ? 'block' : 'hidden'}`}
        >
          <ul className="space-y-6 py-6 px-4">
            <li>
              <Link
                to="/about"
                onClick={handleLinkClick}
                className="block text-gray-800 font-semibold hover:bg-green-600 hover:text-white transition-colors duration-300 py-3 rounded-md"
              >
                {t('about')}
              </Link>
            </li>
            <li>
              <Link
                to="/services"
                onClick={handleLinkClick}
                className="block text-gray-800 font-semibold hover:bg-green-600 hover:text-white transition-colors duration-300 py-3 rounded-md"
              >
                {t('services')}
              </Link>
            </li>
            <li>
              <Link
                to="/portfolio"
                onClick={handleLinkClick}
                className="block text-gray-800 font-semibold hover:bg-green-600 hover:text-white transition-colors duration-300 py-3 rounded-md"
              >
                {t('portfolio')}
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                onClick={handleLinkClick}
                className="block flex justify-center items-center bg-green-600 text-white font-semibold transition-colors duration-300 py-3 px-6 rounded-md shadow-lg hover:bg-green-700 transform hover:scale-105"
              >
                <FaEnvelope className="mr-2" />
                {t('contact.title')}
              </Link>
            </li>
          </ul>

          {/* Cambio de idioma en móvil */}
          <div className="flex justify-center space-x-4 py-4">
            <button onClick={() => changeLanguage('es')} className="p-2 rounded-full">
              <img src="/images/spain-flag.png" alt="Español" className="w-8 h-8" />
            </button>
            <button onClick={() => changeLanguage('en')} className="p-2 rounded-full">
              <img src="/images/uk-flag.jpeg" alt="English" className="w-8 h-8" />
            </button>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
