import React, { useEffect } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';
import { useTranslation } from 'react-i18next';

const services = [
  {
    key: 'pci',
    image: 'images/alarma-de-incendios-2.jpg',
  },
  {
    key: 'cctv',
    image: 'images/CCTV.jpg',
  },
  {
    key: 'control_acceso',
    image: 'images/Control-de-acceso.jpeg',
  },
  {
    key: 'alarma_intrusos',
    image: 'images/alarma-contra-intrusos.jpeg',
  },
  {
    key: 'red_datos',
    image: 'images/red-de-datos.jpg',
  },
  {
    key: 'sonorizacion',
    image: 'images/sonorizacion.jpg',
  },
  {
    key: 'domotica',
    image: 'images/domotica.jpeg',
  },
  {
    key: 'citofonia',
    image: 'images/citofonia.jpg',
  },
  {
    key: 'proyectos_especiales',
    image: 'images/Proyecto5_2.jpg',
  },
];

function Services() {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  return (
    <section id="services" className="bg-gray-100 py-20 font-poppins">
      <div className="container mx-auto px-4">
        {/* Title */}
        <div className="text-center mb-16">
        <h2 className="text-4xl font-semibold text-blue-800 animate__animated animate__fadeInDown">
  {t('services_details.title')}
</h2>
<p className="text-lg text-gray-600 mt-4 italic animate__animated animate__fadeIn animate__delay-1s">
  {t('services_details.description')}
</p>
        </div>

        {/* Services Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
          {services.map((service, index) => (
            <div
              key={index}
              className="service-card group relative overflow-hidden rounded-lg shadow-lg transform transition duration-500 hover:scale-105"
              data-aos="fade-up"
            >
              {/* Image with Overlay */}
              <div className="service-image relative w-full h-72 overflow-hidden">
                <img
                  src={service.image}
                  alt={t(`services_details.${service.key}.title`)}
                  className="w-full h-full object-cover transition-transform duration-700 group-hover:scale-110"
                />
                <div className="absolute inset-0 bg-blue-900 bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-500"></div>
              </div>

              {/* Hover Description */}
              <div className="absolute inset-0 flex items-center justify-center text-center p-6 opacity-0 group-hover:opacity-100 transition-opacity duration-700 text-white bg-black bg-opacity-60">
                <p className="text-lg font-medium">{t(`services.${service.key}.description`)}</p>
              </div>

              {/* Title */}
              <div className="absolute bottom-4 left-4 text-white z-10 group-hover:translate-y-3 transition-transform duration-500">
                <h3 className="text-2xl font-bold">{t(`services.${service.key}.title`)}</h3>
              </div>
            </div>
          ))}
        </div>

        {/* FAQ Section */}
        <div className="mt-20">
          <h3 className="text-3xl font-bold text-blue-800 mb-6" data-aos="fade-right">{t('faq.title')}</h3>
          <div className="space-y-4">
            <details className="bg-white p-4 rounded-lg shadow-md border border-gray-200" data-aos="fade-up">
              <summary className="font-semibold cursor-pointer">{t('faq.installation_time')}</summary>
              <p className="text-gray-600 mt-2">{t('faq.installation_time_answer')}</p>
            </details>
            <details className="bg-white p-4 rounded-lg shadow-md border border-gray-200" data-aos="fade-up" data-aos-delay="100">
              <summary className="font-semibold cursor-pointer">{t('faq.costs')}</summary>
              <p className="text-gray-600 mt-2">{t('faq.costs_answer')}</p>
            </details>
            <details className="bg-white p-4 rounded-lg shadow-md border border-gray-200" data-aos="fade-up" data-aos-delay="200">
              <summary className="font-semibold cursor-pointer">{t('faq.maintenance')}</summary>
              <p className="text-gray-600 mt-2">{t('faq.maintenance_answer')}</p>
            </details>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
